import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ICigarLog,
  IHumidorLite,
  IHumidorMeasurementResponse,
  IHumidorNames,
  IHumidorTransaction,
} from '@models';
import { UserType } from '@shared/models/user.model';

import queryString from 'query-string';
@Injectable({
  providedIn: 'root',
})
export class HumidorsService {
  constructor(private http: HttpClient) {}

  getHumidorsLite() {
    return this.http.get<IHumidorLite[]>('humidors/lite');
  }

  getUserHumidors(userId: string, userType: UserType) {
    return this.http.get<IHumidorLite[]>(
      `social/${userType}/${userId}/humidorslist`
    );
  }

  getHumidorNames() {
    let params = new HttpParams();
    params = params.set('IsHumidor', 'true');

    return this.http.get<IHumidorNames[]>('cigars/mylistings', {
      params: params,
    });
  }

  addCigar(humidorId: string, productId: number) {
    return this.http.post<ICigarLog>(
      `humidors/${humidorId}/cigars/${productId}`,
      {}
    );
  }

  updateQuantity(humidorId: string, productId: number, quantity: number) {
    return this.http.put<ICigarLog>(
      `humidors/${humidorId}/cigars/${productId}/${quantity}`,
      {}
    );
  }

  removeCigar(humidorId: string, productId: number) {
    return this.http.delete<void>(
      `humidors/${humidorId}/cigars/${productId}`,
      {}
    );
  }

  /**
   * copy a log keeps the user uploaded images, that's the reason
   * why we copy and not addCigar
   */
  copyItem(humidorId: string, logId: number) {
    return this.http.post(
      `cigarlogs/${logId}/copyto/${humidorId}?DeleteOriginal=false`,
      {}
    );
  }

  createHumidor(Name: string) {
    return this.http.post<IHumidorLite>('humidors', { Name });
  }

  updateHumidor(
    humidorId: string,
    data: Partial<IHumidorLite>,
    changeOwner: boolean
  ) {
    return this.http.put<IHumidorLite>(
      `humidors/lite/${humidorId}?sensorTakeOver=${changeOwner}`,
      data
    );
  }

  deleteHumidor(humidorId: string) {
    return this.http.delete<void>(`humidors/${humidorId}`);
  }

  reorderHumidors(ids: string[]) {
    return this.http.post<IHumidorLite[]>(`humidors/reorder`, ids);
  }

  getTransactionsHistory(
    humidorId: string,
    queryParams: { skip: number; take?: number; productId?: number }
  ) {
    const { skip, take = environment.pageLimit, productId } = queryParams;
    return this.http.get<IHumidorTransaction[]>(
      `humidors/${humidorId}/history?${queryString.stringify({
        take,
        skip,
        productId,
      })}`
    );
  }

  getMeasurements(humidorId: string, dateFrom: string, dateTill: string) {
    return this.http.get<IHumidorMeasurementResponse>(
      `humidors/${humidorId}/sensor/measurements?${queryString.stringify({
        dateFrom,
        dateTill,
      })}`
    );
  }

  reboot(id) {
    return this.http.post(`gateways/${id}/reboot`, {});
  }

  ledOn(id) {
    return this.http.post(`gateways/${id}/led`, {});
  }

  ledOff(id) {
    return this.http.delete(`gateways/${id}/led`, {});
  }
}
